<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <img src="/img/icon.png" />
    <h2 class="title">Cambiar contraseña</h2>
    <div class="one">
      <v-text-field
        :type="showPasswordChange ? 'text' : 'password'"
        class="input"
        placeholder="Contraseña"
        prepend-inner-icon="mdi-lock"
        :append-icon="showPasswordChange ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="() => (showPasswordChange = !showPasswordChange)"
        v-model="passwordChange"
        autocomplete="off"
        :rules="passwordRules"
        solo
      >
      </v-text-field>
    </div>
    <div class="pass">
      <div class="div">
        <v-text-field
          :type="showPasswordChangeConfirm ? 'text' : 'password'"
          class="input"
          placeholder="Confirmar contraseña"
          prepend-inner-icon="mdi-lock"
          :append-icon="showPasswordChangeConfirm ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="
            () => (showPasswordChangeConfirm = !showPasswordChangeConfirm)
          "
          v-model="passwordChangeValidate"
          autocomplete="off"
          :rules="confirmPasswordRules"
          solo
        >
        </v-text-field>
      </div>
    </div>

    <v-row align="center" justify="space-around">
      <v-btn
        depressed
        class="btn-confirm"
        @click="changePassword"
        :loading="loading"
      >
        Confirmar
      </v-btn>
      <v-btn
        depressed
        @click="changeComponent"
        :loading="loading"
        class="btn-cancel"
      >
        Cancelar
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import { ChangePassword, getMessage } from "@/core/auth/";

export default {
  name: "ChangePassword",
  props: ["cognitoUser", "userAttributes"],
  data() {
    return {
      showPasswordChange: false,
      showPasswordChangeConfirm: false,
      passwordChange: "",
      passwordChangeValidate: "",
      loading: false,
      valid: false,
      passwordRules: [
        (value) => !!value || "Ingrese contraseña",
        (value) =>
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-+_!@#$%^&*.,?]).+$/.test(
            value
          ) ||
          "La contraseña debe contener al menos una letra mayúscula, una minúscula, un número y un caracter especial",
        (value) =>
          value.length >= 8 ||
          "La contraseña debe contener al menos 8 caracteres",
      ],
    };
  },
  methods: {
    async changePassword() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;
      try {
        const result = await ChangePassword(
          this.passwordChange,
          this.userAttributes,
          this.cognitoUser
        );
        this.$store.dispatch("config/login", result.token, this.$apollo);
        this.$router.push({ name: "Home" });
      } catch (error) {
        this.$toast.error(getMessage(error), {
          position: "top-right",
        });
      }
      this.loading = false;
    },
    changeComponent() {
      this.$router.push({ name: "Auth" });
    },
  },
  computed: {
    confirmPasswordRules() {
      return [
        (value) => !!value || "Ingrese contraseña",
        (value) =>
          value === this.passwordChange || "Las contraseñas no coinciden",
      ];
    },
  },
  mounted() {
    if (!this.cognitoUser || !this.userAttributes) {
      this.$router.push({ name: "Auth" });
    }
  },
};
</script>

<style scoped src="@/assets/css/login.css"></style>
